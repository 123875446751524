import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const baseUrl = import.meta.env.VITE__API_URL || '';

const httpLink = createHttpLink({
    uri: `${baseUrl}/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
    const params = new URLSearchParams(location.search);
    return {
        headers: {
            ...headers,
            authorization: `bearer ${params.get('key')?.replaceAll('/', ';')}`,
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
        },
    },
});
